// import { debug } from "util";

'use strict';

(function () {

  class ListTurbineCtrl {
    constructor($scope, Util, Auth) {
      this.$scope = $scope;
      this.Util = Util;
      this.isAdmin = Auth.isAdmin;
      this.sharedLevel = localStorage.getItem('sharedLevel');
    }

    $onInit() {
      // console.log('in list turbine: ', this.$scope.sortKey);

      this.$scope.$watch('sortKey', (newValue, oldValue) => {
        if (newValue !== oldValue) {
          var sortKey = newValue;
          // console.log('sort key: ', sortKey);
          this.sort();
        }
      });

      this.sort();
      _.each(this.$scope.turbines, turbine => {
        let turbinesWithSameSerial = _.filter(this.$scope.turbines, { serial: turbine.serial });
        if(turbine.serial && turbinesWithSameSerial && turbinesWithSameSerial.length > 1) {
          //turbine.duplicate = true;
        }
      });
    }

    sort() {
      this.$scope.turbines = _.sortBy(this.$scope.turbines, turbine => {
        // console.log('in sort by: ', this.$scope.sortKey);
        var finding = this.$scope.turbineFindings ? this.$scope.turbineFindings[turbine._id] : null;
        if (this.$scope.sortKey === 'updated_desc') {
          return -(new Date(turbine.updatedAt)).getTime();
        } else if (this.$scope.sortKey === 'updated_asc') {
          return (new Date(turbine.updatedAt)).getTime();
        } else if (this.$scope.sortKey === 'risk_desc') {
          return finding ? -finding.category : -1;
        } else if (this.$scope.sortKey === 'risk_asc') {
          return finding ? finding.category : 0;
        }
        // console.log('turbine.sitePosition: ',!_.isNil(turbine.sitePosition));
        return !_.isNil(turbine.sitePositionV2) ? turbine.sitePositionV2 : 'zzzzzz';

      });
      // console.log('turbines after sort: ', this.$scope.turbines);
    }

    getClass(finding) {
      // console.log('ListTurbineCtrl', finding);
      if (finding)
        return this.Util.getFindingClass(finding);
    }
  }

  angular.module('windmanagerApp')
    .controller('ListTurbineCtrl', ListTurbineCtrl);

})();
